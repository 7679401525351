import React from "react";

class Location extends React.Component {
  render() {
    return (
      <>
        <div className="row panel-content">
          <div className="d-none d-md-block col-md-4"></div>
          <div className="col-md-8 mt-5 text-white">
            <div className="mt-3 background">
              <p>
                La Fondazione Maria Pernici Antica Vetreria ha riportato alla
                luce la storia della antica Fabbrica di Cristalli di Carisolo.
              </p>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-lg-6  mx-auto">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/maria_pernici_400.jpg"
                    alt="Maria Pernici"
                  />
                  {/* <figcaption>
                    Maria Pernici
                  </figcaption> */}
                </figure>
              </div>
            </div>
            <div className="mt-3 background">
              <p>
                I vecchi edifici, restaurati, sono oggi residenza turistica
                estivo-invenale.
              </p>
            </div>
            <div className="mt-3 background">
              <p>
                Come in un museo all'aperto si ritrova il vecchio mulino del
                quarzo, con la ricostruzione della molazza originale in granito,
                per la frantumazione del prezioso minerale e la ruota idraulica
                che la alimentava.
              </p>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-lg-4 text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/mulino_400.jpg"
                    alt="Mulino"
                  />
                  <figcaption>
                    Il mulino del quarzo con la molazza
                  </figcaption>
                </figure>
              </div>
              <div className="col-12 col-lg-4  text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/molazza_400.jpg"
                    alt="Molazza"
                  />
                  <figcaption>La molazza</figcaption>
                </figure>
              </div>
              <div className="col-12 col-lg-4  text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/ruota_400.jpg"
                    alt="Ruota idraulica"
                  />
                  <figcaption>La ruota idraulica del mulino</figcaption>
                </figure>
              </div>
            </div>
            <div className="mt-3 background">
              <p>
                Percorrendo una breve distanza, come gli antichi vetrai, si
                giunge alla casa delle Maestranze, ove dimoravano con le loro
                famiglie e poi ai ruderi della grande Halle, sede della fornace.
                Lo spazio si apre aldilà del suggestivo arco in granito,
                scoprendo un vasto selciato che reca ancora i segni dell'intensa
                attività dei forni. La fornace, a pianta quadra, si rivede nelle
                grandi pietre angolari e nei resti di mattoni refrattari che la
                caratterizzavano (fornace a riverbero).
              </p>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-lg-6  text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/maestranze_inverno_400.jpg"
                    alt="Maestranze"
                  />
                  <figcaption>Casa delle maestranze in inverno</figcaption>
                </figure>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/maestranze_estate_400.jpg"
                    alt="Maestranze"
                  />
                  <figcaption>Casa delle maestranze in estate</figcaption>
                </figure>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-lg-6 text-center">
                <figure >
                  <img
                    className="attached-image"
                    src="./img/collection/arco_400.jpg"
                    alt="Arco"
                  />
                  <figcaption>L'arco in granito della Halle</figcaption>
                </figure>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <figure >
                  <img
                    className="attached-image"
                    src="./img/collection/arco_antica_fornace_400.jpg"
                    alt="Arco"
                  />
                  <figcaption>L'arco dell'antica fornace</figcaption>
                </figure>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/halle_400.jpg"
                    alt="Halle"
                  />
                  <figcaption>La Halle, sede della fornace</figcaption>
                </figure>
              </div>
              <div className="col-12 col-lg-6  text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/pietre_fornace_400.jpg"
                    alt="Pietre"
                  />
                  <figcaption>Pietre angolari della fornace</figcaption>
                </figure>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-lg-6 mx-auto">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/collection/plastico_400.jpg"
                    alt="Plastico"
                  />
                  <figcaption>Plastico che riproduce il complesso dell'antica vetreria nell'Ottocento </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Location;
