import React from "react";

class Location extends React.Component {
  render() {
    return (
      <>
        <div className="row panel-content">
          <div className="d-none d-md-block col-md-4"></div>
          <div className="col-md-8 mt-5 text-white">
            <div className="mt-3 background">
              <p>
                La “Fabbrica dei Cristalli” di Carisolo fu fondata dai soci
                Pernici e Bolognini nel 1805 e fu operativa fino al 1888.
              </p>
            </div>
            <div className="background">
              <p>
                L'insediamento in questo recondito luogo all'imbocco della Val
                di Genova non fu affatto casuale. In Val Rendena, infatti,
                esisteva grande ricchezza di minerali quarziferi, principali
                elementi della composta vetrosa. La zona inoltre era molto
                adatta perché il fiume Sarca in questa piccola piana si
                suddivideva in alcune ramificazioni fornendo la forza idraulica
                per far funzionare il mulino dove veniva frantumato il quarzo e
                la segheria del legname.
              </p>
            </div>
            <div className="background">
              <p>
                Le estese distese di bosco che ricoprivano le vallate fornirono
                l'abbondante quantità di combustibile necessaria per la fornace
                del vetro, che a quell’epoca era alimentata a legna.
              </p>
            </div>
            <div className="background">
              <p>
                Si fecero arrivare dalla Boemia, dall'Alsazia e dalla Lorena le
                maestranze specializzate nell'arte della soffiatura, che
                impostarono la produzione secondo le loro specifiche esperienze
                e capacità.
              </p>
            </div>
            <div className="background">
              <p>
                L'attività intrapresa diede subito i risultati auspicati e con
                la produzione delle cosiddette "galanterie di cristallo ad uso
                di Boemia" la Pernici e Bolognini fu premiata nel 1812 con la
                medaglia d’argento Premio d'Industria che il governo napoleonico
                aveva istituito a Milano.
              </p>
            </div>
            <div className="row">
              <div className="col-12 col-lg-4 text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/history/vaso_particolare_400.jpg"
                    alt="Vaso di cristallo"
                  />
                  <figcaption>
                    Galanterie di cristallo ad uso di Boemia
                  </figcaption>
                </figure>
              </div>
              <div className="col-12 col-lg-4 text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/history/moneta_400.jpg"
                    alt="Monete"
                  />
                  <figcaption>
                  Moneta premio festa d'Agosto
                  </figcaption>
                </figure>
              </div>
              <div className="col-12 col-lg-4 text-center">
                <figure>
                  <img
                    className="attached-image"
                    src="./img/history/mulino_del_quarzo_400.jpg"
                    alt="Mulino del quarzo"
                  />
                  <figcaption>
                    Mulino del quarzo
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Location;
