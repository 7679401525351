import React from "react";
import "./App.scss";

import Location from "./Location";
import History from "./History";
import Collection from "./Collection";
// import Today from "./Today";
import Events from "./Events";

export class App extends React.Component{

  componentDidMount() {
    let $cont = document.querySelector(".cont");
    let $elsArr = [].slice.call(document.querySelectorAll(".el"));
    
    let $closeBtnsArr = [].slice.call(
      document.querySelectorAll(".el__close-btn")
    );

    setTimeout(function () {
      $cont.classList.remove("s--inactive");
    }, 200);

    $elsArr.forEach(function ($el) {
      $el.addEventListener("click", function () {
        if (this.classList.contains("s--active")) return;
        $cont.classList.add("s--el-active");
        this.classList.add("s--active");
      });
    });

    $closeBtnsArr.forEach(function ($btn) {
      $btn.addEventListener("click", function (e) {
        e.stopPropagation();
        $cont.classList.remove("s--el-active");
        document.querySelector(".el.s--active").classList.remove("s--active");
      });
    });
  }
  render() {
    return (
      <div className="App">
        <div className="cont s--inactive">
          <div className="cont__inner">
            <div id="map-element" className="el">
              <div className="el__overflow">
                <div className="el__inner">
                  <div className="el__bg"></div>
                  <div className="el__preview-cont">
                    <h2 className="el__heading d-none d-lg-block">
                      Dove siamo
                    </h2>
                    <h2 className="el__heading d-lg-none rotated">
                      Dove siamo
                    </h2>
                  </div>
                  <div className="el__content p-md-5">
                    <div className="el__text">
                      <Location />
                    </div>
                    <div className="el__close-btn"></div>
                  </div>
                </div>
              </div>
              <div className="el__index">
                <div className="el__index-back">1</div>
                <div className="el__index-front">
                  <div className="el__index-overlay" data-index="1">
                    1
                  </div>
                </div>
              </div>
            </div>
            <div className="el">
              <div className="el__overflow">
                <div className="el__inner">
                  <div className="el__bg"></div>
                  <div className="el__preview-cont">
                    <h2 className="el__heading d-none d-lg-block">La storia</h2>
                    <h2 className="el__heading d-lg-none rotated">La storia</h2>
                  </div>
                  <div className="el__content p-md-5">
                    <div className="el__text">
                      <History />
                    </div>
                    <div className="el__close-btn"></div>
                  </div>
                </div>
              </div>
              <div className="el__index">
                <div className="el__index-back">2</div>
                <div className="el__index-front">
                  <div className="el__index-overlay" data-index="2">
                    2
                  </div>
                </div>
              </div>
            </div>
            <div className="el">
              <div className="el__overflow">
                <div className="el__inner">
                  <div className="el__bg"></div>
                  <div className="el__preview-cont">
                    <h2 className="el__heading d-none d-lg-block">
                      La raccolta storica della fondazione
                    </h2>
                    <h2 className="el__heading d-lg-none rotated">
                      La raccolta storica
                    </h2>
                  </div>
                  <div className="el__content p-md-5">
                    <div className="el__text">
                      <Collection />
                    </div>
                    <div className="el__close-btn"></div>
                  </div>
                </div>
              </div>
              <div className="el__index">
                <div className="el__index-back">3</div>
                <div className="el__index-front">
                  <div className="el__index-overlay" data-index="3">
                    3
                  </div>
                </div>
              </div>
            </div>
            {<></>/* <div className="el">
              <div className="el__overflow">
                <div className="el__inner">
                  <div className="el__bg"></div>
                  <div className="el__preview-cont">
                    <h2 className="el__heading d-none d-lg-block">
                      La lavorazione del vetro soffiato oggi
                    </h2>
                    <h2 className="el__heading d-lg-none rotated">
                      La lavorazione oggi
                    </h2>
                  </div>
                  <div className="el__content p-md-5">
                    <div className="el__text">
                      <Today />
                    </div>
                    <div className="el__close-btn"></div>
                  </div>
                </div>
              </div>
              <div className="el__index">
                <div className="el__index-back">4</div>
                <div className="el__index-front">
                  <div className="el__index-overlay" data-index="4">
                    4
                  </div>
                </div>
              </div>
            </div> */}
            <div className="el">
              <div className="el__overflow">
                <div className="el__inner">
                  <div className="el__bg"></div>
                  <div className="el__preview-cont">
                    <h2 className="el__heading d-none d-lg-block">Eventi</h2>
                    <h2 className="el__heading d-lg-none rotated">Eventi</h2>
                  </div>
                  <div className="el__content p-md-5">
                    <div className="el__text">
                      <Events />
                    </div>
                    <div className="el__close-btn"></div>
                  </div>
                </div>
              </div>
              <div className="el__index">
                <div className="el__index-back">4</div>
                <div className="el__index-front">
                  <div className="el__index-overlay" data-index="4">
                    4
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a
            href="https://www.anticavetreria.it/"
            target="_blank"
            rel="noreferrer"
            className="icon-link icon-link--right text-white mt-3"
          >
            <img
              src="./img/logo-anticavetreira.png"
              alt="Logo Antica vetreria"
            />
          </a>
        </div>

        {/* <a
          href="https://dribbble.com/shots/2802024-Satellite-Website-Prototype"
          target="_blank"
          rel="noreferrer"
          className="icon-link"
        >
          <img
            src="http://icons.iconarchive.com/icons/uiconstock/socialmedia/256/Dribbble-icon.png"
            alt="nice"
          />
        </a> */}
      </div>
    );
  }
}

export default App;
