import React from "react";
import L from "leaflet";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";

import "leaflet/dist/leaflet.css";

const lat = 46.17074654640024;
const lon = 10.748731476018147;
const farZoom = 8;
const nearZoom = 15;

class Location extends React.Component {
  constructor() {
    super();
    this.markerIcon = new L.Icon({
      iconUrl: "img/map-marker-icon.png",
      iconSize: [30, 30],
    });
  }

  componentDidMount() {
    this.map = this.mapInstance.leafletElement;
    setTimeout(() => {
      this.map.flyTo([lat, lon], nearZoom);
    }, 5000);
  }

  render() {
    const position = [lat, lon];
    return (
      <>
        <div className="row panel-content">
          <div className="d-none d-md-block col-md-4"></div>
          <div className="col-md-8 mt-5 text-white">
            <Map
              ref={(e) => {
                this.mapInstance = e;
              }}
              className="map mt-3"
              center={position}
              zoom={farZoom}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position} icon={this.markerIcon}>
                <Popup className="text-center">
                  Noi siamo qui! <br />
                  Visualizza su Goggle Maps. <br />
                  <a
                    href={`https://maps.google.com/?ll=${lat},${lon}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    link
                  </a>
                </Popup>
              </Marker>
            </Map>
            <div className="mt-3 background">
              <h2>Da Trento</h2>
              <p>
                si segue la SS n. 45 della Gardesana Occidentale fino a Sarche.
                <br />
                Si prosegue fino a Tione SS n. 237.
                <br />
                Da Tione in direzione nord (SS n.239) si risale la Val Rendena
                fino a Carisòlo
              </p>
            </div>
            <div className="background">
              <h2>Da Breascia</h2>
              <p>
                si percorre prima la Val Sabbia poi la Val del Chiese e infine
                la Val Rendona in direzione Madonna di Campiglio fino a
                Carisolo.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Location;
