import React from "react";

class Location extends React.Component {
  render() {
    return (
      <>
        <div className="row panel-content">
          <div className="d-none d-md-block col-md-4"></div>
          <div className="col-md-8 mt-5 text-white">
            <div className="mt-3 row">
              <div className="col-12 col-sm-6">
                <h2>In aggiornamento...</h2>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row panel-content">
          <div className="d-none d-md-block col-md-4"></div>
          <div className="col-md-8 mt-5 text-white">
            <div className="mt-3 row">
              <div className="col-12 col-sm-6">
                <h2>Mostra Vetrocenacolo</h2>
                <p>Castello del Buonconsiglio - Trento</p>
                <p>giugno - novembre 2010</p>
              </div>
              <div className="col-12 col-sm-6">
                <h2>Mostra Vetrocenacolo</h2>
                <p>Cattredale Notre Dame Strasburgo</p>
                <p>4 - 19 aprile 2012</p>
              </div>
            </div>
            <div className="text-center mt-4">
              <a
                className="btn btn-light text-decoration-none"
                href="./pdf/Vetrocenacolo.pdf"
                download
              >
                <img
                  className="download-image "
                  src="./img/pdf-icon.png"
                  alt="pdf"
                />
                Scarica il PDF delle presentazioni
              </a>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default Location;
